import { render, staticRenderFns } from "./division_page_help.vue?vue&type=template&id=7a254072&scoped=true&"
import script from "./division_page_help.vue?vue&type=script&lang=js&"
export * from "./division_page_help.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a254072",
  null
  
)

export default component.exports