<template>
  <div>
    <h2>Overview</h2>
    <p>
      Each EverWatch division has a page dedicated to displaying all log entries
      entered in the previous 15 days by default. All log entries are sorted by
      day and displayed in collapsibles that can be expanded to view all log
      entries for the day. Each log entry within the collapsible can be viewed
      by clicking the header.
    </p>
    <p>
      Clicking the checkbox on each log or collapsible allows for selecting
      multiple logs to edit, delete, email, and print.
    </p>

    <v-img
      content-class="help-image-style"
      class="help-image"
      eager
      contain
      src="../../images/help/divison_log.png"
      transition="fade-transition"
      min-height="400px"
      max-height="400px"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <h2>Side Menu</h2>
    <p>
      The division page side menu features quick access to functions to expedite
      workflow. From the top to the bottom, the side menu features the
      following:
    </p>
    <ul>
      <li>
        [Add]: When clicked, it opens the log editor with a new log created.
      </li>
      <li>
        Search field: When you enter a search term and press the arrow or enter
        key, it automatically submits the search term you entered, filtered by
        the current division.
      </li>
      <li>
        View last: When an entry in the drop-down is clicked, the date range of
        the logs currently being viewed is changed. By default, the last 15 days
        are selected.
      </li>
      <li>
        Switch Division: When an entry in the drop-down is clicked, the current
        division is switched.
      </li>
      <li>[Logout]: When clicked, this logs you out of EW.</li>
    </ul>

    <br />
    <v-img
      class="help-image"
      content-class="help-image-style"
      eager
      contain
      src="../../images/help/division_menu.png"
      transition="fade-transition"
      min-height="600px"
      max-height="600px"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
/* eslint-disable */
export default {
  name: "division_page_help",
  data() {
    return {};
  },
  methods: {
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
